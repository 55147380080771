import React, { useState, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Masonry from 'react-masonry-component'

import { ProjectsQuery } from '../../graphql-types'
import { Content, ImgWithEffect } from '../components/styled'
import styled from 'styled-components'
import { DEFAULT_SIZE, MOBILE_BREAKPOINT, BLACK, GRAY } from '../Utils'
import useWindowSize from '../hooks/useWindowSize'

const dedupe = (arr: string[]): string[] =>
  arr.reduce((uniqs, c) => (uniqs.includes(c) ? uniqs : [...uniqs, c]), [])

export const toggle = (arr: string[], value) =>
  arr.includes(value) ? arr.filter((v) => v !== value) : [...arr, value]

// Architects and Categories are actually strings that are delimited with ,,
// This is because initially a project could oly have one of each but later changed to have many of each
// To avoid having to change everything in the CMS, this is a workaround
const extract = (str: string) => str.split(',,').map((a) => a.trim())

const BlogIndex = ({
  data,
  location,
}: {
  data: ProjectsQuery
  location: Location
}) => {
  const allProjects = [
    ...data.allContentfulProject.edges.map((p) => ({ ...p, featured: false })),
    ...data.allContentfulFeaturedProject.edges.map((p) => ({
      ...p,
      featured: true,
    })),
  ]

  const [allCategories, allArchitects, allLocalAuthorities] = [
    dedupe(
      allProjects.reduce(
        (flat, { node }) => flat.concat(node.category.map((a) => a.trim())),
        []
      )
    ),
    dedupe(
      allProjects.reduce(
        (flat, { node }) => flat.concat(extract(node.architect)),
        []
      )
    ),
    dedupe(
      allProjects.reduce(
        (flat, { node }) => flat.concat(extract(node.localAuthority)),
        []
      )
    ),
  ]

  console.log(allProjects, 'allProjects')
  console.log(allLocalAuthorities, 'allLocalAuthorities')

  const [width] = useWindowSize()
  const inMobile = width <= MOBILE_BREAKPOINT
  const [displayCount, setDisplayCount] = useState(30)
  const siteTitle = get(data, 'site.siteMetadata.title')

  const [projects, setProjects] = useState(allProjects)
  const [categories, setCategories] = useState([])
  const [architects, setArchitects] = useState([])
  const [localAuthorities, setLocalAuthorities] = useState([])

  const noneSelected = () =>
    categories.length === 0 &&
    architects.length === 0 &&
    localAuthorities.length === 0

  useEffect(() => {
    if (noneSelected()) {
      return setProjects(allProjects)
    }

    setProjects(
      allProjects.filter(({ node }) => {
        const filterCategory = () =>
          node.category && categories.length > 0
            ? node.category.filter((c) => categories.includes(c)).length > 0
            : true
        const filterArchitect = () =>
          node.architect && architects.length > 0
            ? // ? architects.includes(node.architect)
              extract(node.architect).filter((c) => architects.includes(c))
                .length > 0
            : true
        const filterLocalAuthorities = () =>
          node.localAuthority && localAuthorities.length > 0
            ? // ? localAuthorities.includes(node.localAuthority)
              extract(node.localAuthority).filter((c) =>
                localAuthorities.includes(c)
              ).length > 0
            : true

        console.log(architects, 'architects')
        console.log(categories, 'categories')
        console.log(localAuthorities, 'localAuthorities')
        console.log(projects, 'projects')

        return filterCategory() && filterArchitect() && filterLocalAuthorities()
      })
    )
  }, [categories, architects, localAuthorities])

  return (
    <Layout location={location}>
      <Styled>
        <Content>
          <div>
            <div className={`side`}>
              <Filterable
                items={allCategories}
                set={setCategories}
                currentItems={categories}
                initOpen={false}
                title="Category"
                noneSelected={noneSelected}
              />
              <Filterable
                set={setLocalAuthorities}
                items={allLocalAuthorities}
                currentItems={localAuthorities}
                initOpen={false}
                title="Local Authorities"
                noneSelected={noneSelected}
              />
              <Filterable
                set={setArchitects}
                currentItems={architects}
                items={allArchitects}
                initOpen={false}
                title="Architects"
                noneSelected={noneSelected}
              />
              {!noneSelected() && (
                <div
                  className="title"
                  style={{
                    cursor: 'pointer',
                    marginLeft: '2rem',
                  }}
                  onClick={() => {
                    setCategories([])
                    setArchitects([])
                    setLocalAuthorities([])
                  }}
                >
                  Clear all filters
                </div>
              )}
            </div>
          </div>
          <div style={{ background: '#fff' }}>
            <Helmet title={siteTitle} />

            <WithSidebar>
              <div className="main">
                <MasonryWrapper>
                  {typeof window !== 'undefined' && (
                    <Masonry
                      enableResizableChildren={true}
                      className={'my-gallery-class'}
                      elementType={'div'}
                      options={{
                        transitionDuration: 250,
                        horizontalOrder: true,
                      }}
                      disableImagesLoaded={false}
                      updateOnEachImageLoad={true}
                    >
                      {[
                        ...projects
                          .sort(
                            (a, b) =>
                              Date.parse(b.node.date) - Date.parse(a.node.date)
                          )
                          // .sort((a, b) =>
                          //   a.node.priority === null
                          //     ? 1
                          //     : b.node.priority === null
                          //     ? -1
                          //     : a.node.priority - b.node.priority
                          // )
                          .slice(
                            0,
                            inMobile ? displayCount : allProjects.length
                          )
                          .map(({ node, featured }, i) => (
                            <div
                              key={i}
                              style={{
                                width:
                                  width > MOBILE_BREAKPOINT
                                    ? '33.333%'
                                    : '100%',
                                height: 'auto',
                                padding: '0.5rem 0.5rem 1.6rem 0.5rem',
                              }}
                            >
                              <Link
                                to={`${featured ? '/' : '/project/'}${
                                  node.slug
                                }`}
                              >
                                <ImgWithEffect
                                  fluid={node.featuredImage.fluid}
                                />
                                <div
                                  style={{
                                    lineHeight: '1.26',
                                    marginTop: '0.2rem',
                                  }}
                                >
                                  {node.title ? node.title : node.title}
                                </div>
                              </Link>
                            </div>
                          )),
                      ]}
                    </Masonry>
                  )}
                </MasonryWrapper>
                {inMobile && displayCount < allProjects.length && (
                  <div
                    style={{ textAlign: 'center', cursor: 'pointer' }}
                    onClick={() => setDisplayCount(displayCount + 30)}
                  >
                    More Projects
                  </div>
                )}
              </div>
            </WithSidebar>
          </div>
        </Content>
      </Styled>
    </Layout>
  )
}

const Filterable = ({
  title,
  items,
  set,
  currentItems,
  initOpen = false,
  noneSelected,
}: {
  title: string
  items: string[]
  currentItems: string[]
  set: any
  initOpen: boolean
  noneSelected: () => boolean
}) => {
  const [open, setOpen] = useState(initOpen)
  return (
    <FilterableStyles>
      <div className="title" onClick={() => setOpen(!open)}>
        <div
          className="icon"
          style={{
            transform: open ? 'rotate(0)' : 'rotate(45deg)',
          }}
        >
          <span>×</span>
        </div>

        {title}
      </div>
      <div className={`panel-wrapper ${open ? '' : 'collapsed'}`}>
        <div className="panel">
          {items.sort().map((c) => (
            <span
              key={c}
              style={{
                cursor: 'pointer',
                color:
                  currentItems.includes(c) || noneSelected() ? BLACK : GRAY,
              }}
              onClick={() => set(toggle(currentItems, c))}
            >
              {c}
            </span>
          ))}
        </div>
      </div>
    </FilterableStyles>
  )
}

export const FilterableStyles = styled.div`
  margin-bottom: 2rem;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    color: ${GRAY};
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    transition: 0.2s all;
    cursor: pointer;

    span {
      line-height: 0.7;
    }
  }

  .panel-wrapper {
    display: flex;
    overflow: hidden;

    /* transition: 0.2s all; */
    padding-left: 2rem;
  }

  .panel-wrapper:after {
    content: '';
    height: 50px;
    transition: height 0.3s linear, max-height 0s 0.3s linear;
    max-height: 0px;
  }

  .panel {
    transition: margin-bottom 0.3s cubic-bezier(0, 0, 0, 1);
    margin-bottom: 0;
    max-height: 1000000px;
  }

  .panel-wrapper.collapsed > .panel {
    margin-bottom: -2000px;
    transition: margin-bottom 0.3s cubic-bezier(1, 0, 1, 1), visibility 0s 0.3s,
      max-height 0s 0.3s;
    visibility: hidden;
    max-height: 0;
  }

  .panel-wrapper.collapsed:after {
    height: 0;
    transition: height 0.3s linear;
    max-height: 50px;
  }

  .open {
    max-height: 100rem;
  }

  .close {
    max-height: 0rem;
    /* margin-bottom: 2rem; */
  }

  p {
    cursor: pointer;
  }

  p.hover {
    color: darkgray;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    margin-bottom: 0.6rem;
    padding-left: 1.4rem;
  }
`

const Styled = styled.div`
  .filter-button {
    margin: 2rem 0;
    font-size: 1.3rem;
    cursor: pointer;
    line-height: 0;
  }

  .mobile {
    display: none;
  }

  .side {
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    
    div {
      /* width: 50%; */
    }
    span {
      display: block;
      /* font-size: ${DEFAULT_SIZE}rem; */
    }

    transition: 0.2s all;
  }

  .close {
    /* max-height: 0rem; */
    font-size: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    /* fade out, then shrink */
    transition: opacity .25s,
                font-size .5s .25s,
                margin .5s .25s,
                padding .5s .25s;
  }

  .open {
    max-height: 120rem;
    /* unshrink, then fade in */
    transition: font-size .25s,
                margin .25s,
                padding .25s,
                opacity .5s .25s;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    .mobile {
      display: flex;
    }
    .filter-button {
      display: flex;
    }
    .side {
      /* flex-direction: row; */
    }
  }
`

const MasonryWrapper = styled.div`
  text-align: left;
  position: relative;
  width: calc(100% + 1em);
  margin: -0.5rem -0.5rem 0rem -0.5rem;

  * {
    box-sizing: border-box;
  }
`

const WithSidebar = styled.div`
  display: grid;

  .main {
    /* width: calc(100% - 4rem); */
  }
`

export default BlogIndex

export const pageQuery = graphql`
  query Projects {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulFeaturedProject {
      edges {
        node {
          title
          slug
          architect
          category
          localAuthority
          featuredImage {
            fluid(maxWidth: 1180, background: "rgb:000000") {
              ...GatsbyContentfulFluid
            }
          }
          date
        }
      }
    }
    allContentfulProject {
      edges {
        node {
          title
          slug
          architect
          localAuthority
          featuredImage {
            fluid(maxWidth: 1180, background: "rgb:000000") {
              ...GatsbyContentfulFluid
            }
          }
          category
          date
        }
      }
    }
  }
`
